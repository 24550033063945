.page {
    background-color: rgb(242, 246, 250);
}
.page-container {
    margin: 0 auto;
    justify-content: start;
    display: flex;
    flex: 1px 1;
    flex-direction: column;
    width: 70%;
    padding-top: 20px;
}

.back-text {
    margin: 0;
    margin-left: 10px;
    font-size: 1em;
    font-family: Source-SemiBold;
    color: rgb(5, 25, 47);
}

.back-arrow {
    width: 20px;
}

.back-section {
    cursor: pointer;
    flex-direction: row;
    display: flex;
    justify-content: start;
    align-items: center;
}

.content-section {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.page-title {
    font-size: 3em;
    font-family: Source-SemiBold;
    color: rgb(5, 25, 47);
    margin-bottom: 10px;
}

@media (max-width: 575.98px) {
    .page-container {
        width: 100%;
        margin-left: 12px;
        margin-right: 12px;
        padding-top: 20px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {

}
@media (min-width: 768px) and (max-width: 991.98px) {

}
@media (min-width: 992px) and (max-width: 1199.98px) {

}
@media (min-width: 1200px) and (max-width: 1367.98px) {

}
@media (min-width: 1368px) and (max-width: 1919.98px) {

}
@media (min-width: 1920px) {

}
  
.navBar-web {
  /* display: flex;
  flex-direction: row; */
  /* height: 12vh; */
  background-color: white;
  box-shadow: 0px 1px 10px 1px #00000099;
  /* justify-content: space-between; */
  /* align-items: center; */
}
.navBar-mobile {
  /* background-color: white;
  box-shadow: 0px 1px 10px 1px #00000099; */
}
.nav-bar-align {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* flex: 1px; */
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}
.navbar {
  margin-bottom: 0px;
  background-color: white;
  border: none;
}
.side-bar {
  display: none;
}
.header-logo {
  width: 220px;
  cursor: pointer;
}
.round-user-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #9191914f;
  box-shadow: 0px 12px 24px #9191914f;
  opacity: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
.user-icon {
  position: relative;
  /* top: 8px;
  left: 11px; */
  height: 30px;
  width: 30px;
}
.username {
  margin: 0;
  color: #000000;
  font-size: 1em;
  font-family: "Source-SemiBold";
  overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.userEmail {
  margin: 0;
  color: #000000;
  font-size: 0.8em;
  font-family: "Source-Light";
  overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}
.select-box {
  height: 30px;
  width: 30px;
  margin-left: 10px;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #05192f26;
  cursor: pointer;
}
.down-icon {
  color: #05192f20;
  position: relative;
  top: 8px;
  left: 8px;
}
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdownMenu {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  /* display: none;
  position: absolute;
  left: 0px;
  min-width: 160px;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1; */
  position: absolute;
  /* top: 100%;
  left: 0;
  width: 300px; */
  top: 41px;
  right: -3px;
  /* width: 114px; */
  width: 167px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.items {
  padding: 8px 12px;
  color: #05192f;
  font-family: "Source-Reg";
}

.items:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.logout-icon {
  position: relative;
  top: 4px;
  right: 4px;
}
.header-login-Btn {
  padding: 8px;
  border: none;
  border-radius: 3px;
  outline: none;
  text-align: center;
  font-family: "Source-Reg";
  font-size: 0.9em;
  background-color: #1976D2;
  color: white;
  width: 89px;
  cursor: pointer;
  margin-right: 10px;
}
.header-login-Btn:hover {
  background-color: #0D47A1;
  color: white;
}

.header-login-Rtr {
  padding: 8px;
  border: none;
  border-radius: 3px;
  outline: none;
  text-align: center;
  font-family: "Source-Reg";
  font-size: 0.9em;
  background-color: white;
  color: #1976D2;
  width: 89px;
  cursor: pointer;
  margin-right: 10px;
}
.header-login-Rtr:hover {
  background-color: white;
  color: #0D47A1;
}

.active-border {
  /* border-bottom: 3px solid red; 
  width: 66%; 
  position: relative;
  top: 7px;
  left: 9px; */
  border-radius: 121px;
  position: relative;
  top: 7px;

  height: 3px;
  background-color: #0D47A1;
  width: 23px;
}
.home-active {
  left: 22px;
}

.pricing-active {
  left: 10px;
}
.all-forecast-active {
  left: 31px;
}
.sc-active {
  left: 48px;
}
.mobile-app-active {
  left: 26px;
}
.about-active {
  left: 10px;
}
.inactive-border {
  display: none;
}
.home {
  margin-right: 20px;
  cursor: pointer;
  color: #05192f;
  font-size: 1.1em;
  text-align: center;
}
.inactive-home {
  margin-right: 20px;
  cursor: pointer;
  color: #05192f;
  font-size: 1.1em;
  color: #a9a9a9;
}

@media (max-width: 575.98px) {
  .nav-bar-align {
    max-width: 100%;
  }
  .navbar-toggle{
    margin-right: 0;
  }
  .round-user-icon {
    display: none;
  }
  .userEmail {
    display: none;
  }
  .username {
    display: none;
  }
  .select-box {
    display: none;
  }
  .side-bar {
    display: block;
  }
  .header-logo {
    position: relative;
    top: -12px;
    left: 40px;
  }
  .header-login-Rtr {
    display: none;
  }
  .header-login-Btn {
    display: none;
  }
  .home {
    display: none;
  }
  .inactive-home {
    display: none;
  }
  .navBar-web {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  /* .nav-bar-align {
    max-width: 540px;
  } */
  .side-bar {
    display: block;
  }
  .header-logo {
    position: relative;
    top: -12px;
    left: 40px;
  }
  .header-login-Rtr {
    display: none;
  }
  .header-login-Btn {
    display: none;
  }
  .home {
    display: none;
  }
  .inactive-home {
    display: none;
  }
  .navBar-web {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .nav-bar-align {
    max-width: 768px;
  }
  .header-login-Rtr {
    width: 70px;
    /* margin-left: 4px; */
  }
  .header-login-Btn {
    width: 70px;
    margin-left: 4px;
  }

  .home-active {
    left: 14px;
  }
  .sc-active {
    left: 24px;
  }
  .pricing-active {
    left: 5px;
  }
  .all-forecast-active {
    left: 18px;
  }
  .mobile-app-active {
    left: 9px;
  }
  .all-forecast-active {
    left: 14px;
  }
  .about-active {
    left: 4px;
  }
  .header-logo {
    width: 130px;
  }
  .round-user-icon {
    height: 40px;
    width: 40px;
  }
  .user-icon {
    height: 20px;
    width: 20px;
  }
  .inactive-home {
    font-size: 0.7em;
    margin-left: 1px;
    margin-right: 7px;
  }
  .home {
    font-size: 0.7em;
    margin-left: 1px;
    margin-right: 3px;
  }
  .username {
    font-size: 0.9em;
  }
  .userEmail {
    font-size: 0.8em;
  }
  .select-box {
    height: 20px;
    width: 20px;
  }
  .down-icon {
    top: 3px;
    left: 4px;
  }

  .navBar-mobile {
    display: block;
  }
  .navBar-web {
    display: block;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .nav-bar-align {
    max-width: 962px;
  }
  .sc-active {
    left: 42px;
  }
  .all-forecast-active {
    left: 24px;
  }
  .mobile-app-active {
    left: 20px;
  }
  .all-forecast-active {
    left: 27px;
  }
  .inactive-home {
    margin-right: 10px;
    font-size: 0.9em;
  }
  .home {
    margin-right: 8px;
    font-size: 0.9em;
  }
  .navBar-mobile {
    display: block;
  }
  .navBar-web {
    display: block;
  }
  .round-user-icon{
    width: 40px;
    height: 40px;
  }
  .user-icon{
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .nav-bar-align {
    max-width: 1140px;
  }
  .navBar-mobile {
    display: block;
  }
  .navBar-web {
    display: block;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .nav-bar-align {
    max-width: 1200px;
  }
  .navBar-mobile {
    display: none;
  }
}
@media (min-width: 1920px) {
  .nav-bar-align {
    max-width: 1700px;
  }
  .navBar-mobile {
    display: none;
  }
}
.navBarItems {
  position: relative;
}
.nav-mmenu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2em;
}

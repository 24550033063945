/* img {
    width: 100%;
} */
.outer {
  position: relative;
  height: calc(100% - 90px);
}
.map-wrap {
  /* max-width: 1200px; */
  margin: auto;
  position: relative;
  height: 100%;
}
.wrapper {
  max-width: 1200px;
  margin: auto;
}

#map-canvas {
  position: absolute;
  top: 0;
  /* top: 100px; */
  bottom: 0;
  width: 100%;
  height: 100%;
}
/* .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font: bold 18px 'Lucida Console', Monaco, monospace;
    text-indent: 1px;
    }
  .leaflet-control-zoom-out {
    font-size: 20px; 
    }
  
  .leaflet-touch .leaflet-control-zoom-in {
    font-size: 22px;
    }
  .leaflet-touch .leaflet-control-zoom-out {
    font-size: 24px;
    } */

.legend-wrap {
  width: 420px;
  top: 1px;
  /* top:150px; */
  left: 300px;
  position: absolute;
  /* opacity: 0.6; */
  border-radius: 3px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #0000004a;
}

.legend-wrap img {
  display: none;
}

.legend-wrap.height img:first-child {
  display: block;
}

.legend-wrap.period img:last-child {
  display: block;
}
.leaflet-touch .leaflet-bar,
.leaflet-touch .leaflet-control-layers {
  border: 1px solid rgba(48, 82, 96, 0.16);
  background-clip: padding-box;
  box-shadow: 0px 0px 5px #0000004a;
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.leaflet-touch .leaflet-bar a {
  /* width: 30px; */
  background-color: #1976D2;
  color: white;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  width: 35px;
  height: 35px;
  background-size: 35px;
  opacity: 1;
  box-shadow: 0px 0px 5px #0000004a;
}
.leaflet-control-container {
  position: relative;
  max-width: 1200px;
  margin: auto;
}
.leaflet-top.leaflet-left {
  top: 72px;
  left: auto;
  right: 0px;
}
.leaflet-bar .leaflet-control-zoom-out {
  background-position: 0 -35px;
}

.spot-name{
      color: #fff;
    padding: 10px 0;
    font-size: 20px;
    height: 50px;
    font-weight: bold;
    display: inline-block;
    text-decoration: none;
}
.spot-name:hover{
  color: #fff;
}
.map-type-wrap {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 15px #0000004a;
  display: flex;
  position: absolute;
  bottom: 125px;
  right: 0px;
  font-size: 14px;
  color: #333;
}

.map-type-wrap .type {
  padding: 5px 10px;
  border-right: 1px solid #5482b766;
  cursor: pointer;
}

.map-type-wrap .type:first-child {
  border-right: none;
  border-radius: 3px 0px 0px 3px;
}

.map-type-wrap .type:last-child {
  border-right: none;
  border-radius: 0px 3px 3px 0px;
}

.map-type-wrap .type:hover,
.map-type-wrap .type.active {
  background: #1565C0;
  color: #ffffff;
}
.option-wrap {
  width: 1200px;
  margin: auto;
  /* position: absolute;
    bottom: 0px; */
}
.info-box-wrap {
  position: absolute;
  bottom: 20px;
  left: 0px;
  right: 0px;
  max-width: 1200px;
  margin: auto;
  /* height: 100px; */
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px #0000004a;
  display: flex;
  padding: 10px 20px;
  box-sizing: border-box;
}
.direction-title-wrap {
  position: absolute;
  bottom: 125px;
  left: 0px;
}

.title-wrap {
  /* border-right: 1px solid #dddddd; */
  /* padding-right: 20px;
    margin-right: 20px;
    width: 150px; */
    display: flex;
    align-items: center;
}

.title-wrap h2 {
  margin: 10px 0;
  font-size: 23px;
}

.title-wrap p {
  margin: 10px 0;
  /* color: #666; */
  /* font-size: 14px; */
  /* font-weight: bold; */
}

.title-wrap .play-btn {
  /* display: none; */
}

.slider-container {
  display: flex;
  align-items: center;
  /* width: calc(100% - 150px); */
  /* width: calc(100% - -10px); */
  width: calc(100% - 120px);
  justify-content: space-between;
  position: relative;
}
.slider-legend-day {
  color: #05192f;
  font-family: Source-Reg;
  font-size: 1.2em;
}
.slider-legend-date {
  color: "#05192F";
  font-family: "Source-SemiBold";
  font-size: 1.2em;
}
.title-wrap-date {
  color: white;
  font-family: "Source-Bold";
  font-size: 3em;
  margin: 0;
}
.title-wrap-time {
  color: white;
  font-family: Source-SemiBold;
  font-size: 1em;
  margin: 0;
  margin-bottom: 10px;
}

.play-btn {
  width: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  /* margin-right: 30px; */
}
.play-btn svg{
  width: 30px;
  height: 30px;
}

.play-btn .pause_btn:last-child {
  display: none;
}

.play-btn.active .play_btn {
  display: none;
}

.play-btn.active .pause_btn {
  display: block;
}

.slider-wrap {
  /* width: calc(100% - 70px); */
  width: 100%;
  z-index: 1;
}

.time-wrap {
  font-size: 12px;
  width: 150px;
  /* margin-left: 30px; */
}

.ui-widget.ui-widget-content {
  border: 1px solid #5482b7;
  background: #5482b7;
  height: 5px;
  border-radius: 10px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
  border: 5px solid #48bcec;
  background: #3d6797;
  font-weight: normal;
  color: #454545;
  border-radius: 50%;
  top: -8px;
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 10px 1px #48bcec;
  outline: none;
  cursor: pointer;
  transition: all 0.5s;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 5px solid #48bcec;
  background: #3d6797;
  font-weight: normal;
  color: #2b2b2b;
  box-shadow: 0px 0px 10px 3px #48bcec;
  transform: scale(1.1);
}

.slider-legend-wrap {
  display: flex;
  font-size: 10px;
  margin-top: 15px;
  justify-content: space-between;
}

.slider-legend-wrap .legend-item {
  border-right: 1px solid #dddddd;
  flex-grow: 1;
  text-align: center;
}

.slider-legend-wrap .legend-item:last-child {
  border-right: none;
}

.slider-legend-wrap .legend-item span {
  display: block;
}

.slider-legend-wrap .legend-item.time {
  display: none;
}

.slider-legend-wrap-date-details {
  display: flex;
  font-size: 10px;
  margin-top: 15px;
  justify-content: space-between;
}

.slider-legend-wrap-date-details .legend-item {
  border-right: 1px solid #dddddd;
  flex-grow: 1;
  text-align: center;
}

.slider-legend-wrap-date-details .legend-item:last-child {
  border-right: none;
}

.slider-legend-wrap-date-details .legend-item span {
  display: block;
}

.slider-legend-wrap-date-details .legend-item.time {
  display: none;
}

.direction-toggle {
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 15px #0000004a;
  display: flex;
  /* position: absolute;
    bottom: 125px;
    left: 0px; */
  font-size: 12px;
  color: #333;
  padding: 5px;
  margin-bottom: 10px;
}
.direction-toggle:last-child{
  margin-bottom: 0;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  outline: none;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
  margin-right: 5px;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 5px;
  display: inline-block;
  vertical-align: text-top;
  width: 17px;
  height: 17px;
  border-radius: 3px;
  /* background: white; */
  border: 1px solid #dddddd;
}

.styled-checkbox:hover + label:before {
  /* background: #f35429; */
}

.styled-checkbox:focus + label:before {
  /* box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12); */
}

.styled-checkbox:checked + label:before {
  /* background: #f35429; */
  border: 1px solid #f76c6c12;
  background-color: #f76c6c12;
}

.styled-checkbox:checked + label {
  color: #f76c6c;
}

.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #f76c6c;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #f76c6c, 4px 0 0 #f76c6c, 4px -2px 0 #f76c6c,
    4px -4px 0 #f76c6c, 4px -6px 0 #f76c6c, 4px -8px 0 #f76c6c;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.location-box-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  top: 30px;
}
.location-box-wrap {
  /* margin-left: 30px; */
  background-color: white;
  box-shadow: rgba(182, 182, 182, 0.16) 0px 15px 30px;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}
.left-arrow-wrap {
  background-color: rgba(247, 108, 108, 0.07);
  padding: 15px;
  cursor: pointer;
}
.loc_round {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: white;
  font-family: "Source-Bold";
  font-size: 0.8em;
  background-color: #1976D2;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loc_name {
  margin: 0px 0px 0px 4px;
  text-align: left;
  color: rgb(5, 25, 47);
  font-size: 1em;
  font-family: "Source-Semibold";
  width: 135px;

}
.down-box {
  height: 20px;
  width: 20px;
  margin-left: 10px;
  margin-right: 8px;
  border-radius: 2px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(5, 25, 47, 0.15);
  cursor: pointer;
  position: relative;
}
.dropdown-items {
  position: absolute;
  top: 55px;
  right: 0px;
  width: 162px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.windy-dropdown-items {
  position: absolute;
  top: 45px;
  right: 2px;
  width: 140px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}
.wind-btn-wrap{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  top: -72px;
}
.wind-Button {
  width: 90px;
  height: 40px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 15px 30px #b6b6b629;
  border-radius: 4px;
  opacity: 1;
  outline: none;
  border: none;
  color: #05192f;
  cursor: pointer;
  font-size: 1.1em;
  font-family: "Source-SemiBold";
}
.wind-Button:hover {
  background: #b6b6b690;
}
.list_btn {
  width: 80px;
  padding: 4px;
  border: none;
  background-color: white;
  cursor: pointer;
  color: rgb(0, 0, 0);
  outline: none;
  font-family: "Source-Light";
}

.map_btn {
  width: 80px;
  padding: 4px;
  border: none;
  outline: none;
  color: white;
  background-color: rgb(247, 108, 108);
  cursor: pointer;
}
.swell-height-wrap{
  width: 100%;
  position: absolute;
  height: 50px;
    margin-bottom: 10px;
    z-index: 0;
}
.swell-height-legends-wrap{
      position: absolute;
    left: -20px;
    font-size: 11px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.swell-height-legends-wrap p{
  margin: 0;
}
.height-graph-wrap{
  display: flex;
  height: 50px;
  align-items: flex-end;
}
.height-item{
  width: 10%;
  background: #05192f44;
  height: 60px;
  border-top: 1px solid #3832a6;
  border-radius: 2px;
  background: rgb(56,50,166);
background: linear-gradient(180deg, rgba(56,50,166,0.6) 0%, rgba(56,50,166,0) 100%);
}
.subcribe-box-align {
  bottom: -4px;
  right: 0px;
  /* width:35%; */
   width:57%;
   z-index: 1;
}
.subscribe-box-wrap {
  height: 22px;
  width: 99%;
  padding: 35px;
  background-color: rgb(249, 235, 173, 0.98);
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: right;
  /* padding: 18px;
    background-color: rgb(249, 235, 173, 0.9);
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center; */
  justify-content: space-between;
}
.subscribe-box-title {
  margin: 0;
  font-size: 1em;
  /* color:#273641; */
  color: #05192f;
  font-family: "Source-SemiBold";
}
.subscribe-box-subtext {
  margin: 0;
  font-size: 0.9em;
  color: #273641;
  font-family: "Source-SemiBold";
}
.subscribe_btn {
  background-color: #1976D2;
  border: none;
  width: 134px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  padding: 7px;
  border-radius: 4px;
  color: white;
  font-family: "Source-SemiBold";
  font-size: 0.9em;
  margin-left: 16px;
}
.swell-map-container {
  margin: 0 auto;
}
.items {
  padding: 8px 12px;
  color: #05192f;
  font-family: "Source-Reg";
}

.items:hover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}
.slider-legend-wrap-time {
  display: flex;
  font-size: 10px;
  margin-top: 15px;
  justify-content: space-between;
}

.slider-legend-wrap-time .legend-item {
  border-right: 1px solid #dddddd;
  flex-grow: 1;
  text-align: center;
}

.slider-legend-wrap-time .legend-item:last-child {
  border-right: none;
}

.slider-legend-wrap-time .legend-item span {
  display: block;
}

.slider-legend-wrap-time .legend-item.time {
  display: none;
}
.popup-container{
  background: #00000099;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-outer{
  max-width: 400px;
  background: #ffffff;
  border-radius: 5px;
  border-right: 1px solid #dddddd;
  padding: 20px 30px;
  text-align: center;
  position: relative;
}
.popup-outer p{
  font-size: 14px;
  margin-bottom: 40px;
}
.close-icon{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 15px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .popup-outer{
    max-width: 350px;
  }
  .subcribe-box-align{
    /* width: 32%; */
    width: 51%;
  }
  .subscribe-box-title, .subscribe-box-subtext{
    display: none;
  }
  .outer {
    position: relative;
    height: calc(100% - 77px);
  }
  .info-box-wrap {
    /* flex-direction: column; */
    margin: 0 15px;
  }
  .title-wrap {
    /* width: 100%; */
    border-right: 0;
    margin-right: 0;
    padding-right: 0;
    /* margin-bottom: 20px; */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .title-wrap h2 {
    font-size: 18px;
  }
  .title-wrap p {
    margin: 0;
  }
  .title-wrap .play-btn {
    display: none;
    /* width: 20px; */
    /* margin-left: 15px; */
    margin-right: 15px;
  }
  .title-wrap .play-btn:nth-child(2){
    display: block;
  }
  .slider-container {
    width: 100%;
    position: relative;
  }
  .slider-container .play-btn {
    display: none;
  }
  .map-type-wrap,
  .direction-toggle {
    bottom: 178px;
    right: 15px;
  }
  .slider-wrap {
    width: 100%;
  }
  .slider-legend-wrap {
    display: none;
  }
  .swell-map-container {
    max-width: 536px;
  }
  .legend-wrap {
    width: 300px;
    right: 10px;
    left: auto;
    position: absolute;
    left: 0px;
    top: 72px;
  }
  .loc_round {
    display: none;
  }
  .loc_name {
    display: none;
  }
  .swellMapdropdownMenu {
    display: none;
  }
  .location-box-align {
    top: 10px;
  }

  .play-pause-web {
    display: none;
  }
  .direction-title-wrap {
    bottom: 178px;
    left: 15px;
  }
  .leaflet-top.leaflet-left, .wind-Button{
    margin-right: 15px;
  }
  .leaflet-top .leaflet-control{
    margin-top: 0;
  }
  .legend-wrap {
    width: 350px;
    /* right: 10px; */
    left: auto;
    position: absolute;
    left: 62px;
    top: 2px;
  }
  .slider-legend-wrap-date-details {
    margin-bottom: 10px;
  }
  .wind-btn-wrap{
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .legend-wrap {
    /* width: 300px;
      right: 10px;
      left: auto;
      margin-top: 54px; */

    width: 205px;

    /* margin-top: 7px; */
    top: 2px;
    position: absolute;
    left: 60px;
  }
  .play-btn .fast-forward .reverse{
    width: 30px;
    margin-right: 20px;
  }

  .swell-map-container {
    max-width: 100%;
  }
  .loc_round {
    display: none;
  }
  .loc_name {
    display: none;
  }
  .swellMapdropdownMenu {
    display: none;
  }
  .location-box-align {
    top: 10px;
  }
  .left-arrow-wrap {
    padding: 6px;
  }

  .wind-Button {
    height: 35px;
    margin-top: 10px;
    width: 90px;
    /* margin-right: 10px; */
  }
  .location-box-wrap {
    margin-left: 15px;
    width: 32px;
  }
  .spot-name{
    margin-left: 10px;
  }
  .map-type-wrap,
  .direction-toggle {
    bottom: 123px;
  }
  .subcribe-box-align{
    top: 6px;
  }
  .subscribe-box-wrap {
    /* padding: 15px; */
    /* width: 254px; */
    height: 100%;

    padding: 30px 10px;
    /* padding:35px; */
  }
  .subscribe_btn{
    margin: 0;
  }
  .play-pause-web {
    display: none;
  }
  .direction-title-wrap {
    bottom: 123px;
  }
  .slider-legend-wrap {
    display: none;
  }
  .slider-legend-wrap-date-details {
    margin-bottom: 10px;
  }
  .slider-legend-wrap-time {
    display: flex;
    font-size: 9px;
    margin-top: 10px;
    justify-content: space-between;
  }
  .info-box-wrap {
    padding: 1px 1px 1px 5px;
    bottom: 15px;
  }
  .info-box-wrap {
    /* margin-left: 0px; */
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .swell-map-container {
    max-width: 720px;
  }
  .legend-wrap {
    width: 373px;
  }
  .play-pause-mobile {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .swell-map-container {
    max-width: 960px;
  }
  .play-pause-mobile {
    display: none;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .swell-map-container {
    max-width: 1140px;
  }
  .option-wrap {
    position: absolute;
    bottom: 0px;
    left: 0px;
  }
  .play-pause-mobile {
    display: none;
  }
  .direction-title-wrap {
    left: 11px;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .swell-map-container {
    max-width: 1200px;
  }
  .option-wrap {
    position: absolute;
    bottom: 0px;
  }
  .play-pause-mobile {
    display: none;
  }
}
@media (min-width: 1920px) {
  .swell-map-container {
    max-width: 1700px;
  }
  .option-wrap {
    position: absolute;
    bottom: 0px;
  }
  .play-pause-mobile {
    display: none;
  }
}

.marker-cluster-small {
	background-color: rgba(181, 226, 140, 0.6);
	}
.marker-cluster-small div {
	background-color: rgba(110, 204, 57, 0.6);
	}

.marker-cluster-medium {
	background-color: rgba(241, 211, 87, 0.6);
	}
.marker-cluster-medium div {
	background-color: rgba(240, 194, 12, 0.6);
	}

.marker-cluster-large {
	background-color: rgba(253, 156, 115, 0.6);
	}
.marker-cluster-large div {
	background-color: rgba(241, 128, 23, 0.6);
	}

	/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
	background-color: rgb(181, 226, 140);
	}
.leaflet-oldie .marker-cluster-small div {
	background-color: rgb(110, 204, 57);
	}

.leaflet-oldie .marker-cluster-medium {
	background-color: rgb(241, 211, 87);
	}
.leaflet-oldie .marker-cluster-medium div {
	background-color: rgb(240, 194, 12);
	}

.leaflet-oldie .marker-cluster-large {
	background-color: rgb(253, 156, 115);
	}
.leaflet-oldie .marker-cluster-large div {
	background-color: rgb(241, 128, 23);
}

.marker-cluster {
	background-clip: padding-box;
	border-radius: 20px;
  transition: all 0.5s;
	}
.marker-cluster div {
	width: 30px;
	height: 30px;
	margin-left: 5px;
	margin-top: 5px;

	text-align: center;
	border-radius: 15px;
	font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
	}
.marker-cluster span {
	line-height: 30px;
	}
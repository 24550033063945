/* @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext'); */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} 
/* *{
  box-sizing: border-box;
} */
body,
html {
  height: 100%;
  /* width: 100vw; */
  margin: 0;
  font-size: 16px;
  font-family: "Source-Reg";
  overflow-x: hidden;
  /* overflow: hidden; */
}

.primary-color {
  color: #1976D2!important;
}
.white-color {
  color: white!important;
}
.pointer {
  cursor: pointer;
}
/************** FLEX HELPERS ***************/
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}
.flex-end {
  justify-content: flex-end;
}
.flex-center {
  justify-content: center;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-space-around {
  justify-content: space-around;
}
.flex-space-evenly {
  justify-content: space-evenly;
}

.flex-align-center {
  align-items: center;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-align-end {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}
.flex-25 {
  flex: 0.25;
}
.flex-50 {
  flex: 0.5;
}
.flex-75 {
  flex: 0.75;
}
/************** UTIL HELPERS ***************/
.w-100 {
  width: 100%!important;
}

.mt-0 {
  margin-top: 0!important;
}
.mt-1 {
  margin-top: 1em!important;
}
.mt-2 {
  margin-top: 2em!important;
}
.mt-3 {
  margin-top: 3em!important;
}

.mb-0 {
  margin-bottom: 0!important;
}
.mb-1 {
  margin-bottom: 1em!important;
}
.mb-2 {
  margin-bottom: 2em!important;
}
.mb-3 {
  margin-bottom: 3em!important;
}

.ml-0 {
  margin-left: 0!important;
}
.ml-1 {
  margin-left: 1em!important;
}
.ml-2 {
  margin-left: 2em!important;
}
.ml-3 {
  margin-left: 3em!important;
}

.mr-0 {
  margin-right: 0!important;
}
.mr-1 {
  margin-right: 1em!important;
}
.mr-2 {
  margin-right: 2em!important;
}
.mr-3 {
  margin-right: 3em!important;
}

.p-0 {
  padding: 0!important;
}
.p-1 {
  padding: 1em!important;
}
.p-2 {
  padding: 2em!important;
}
.p-3 {
  padding: 3em!important;
}

.pt-0 {
  padding-top: 0!important;
}
.pt-1 {
  padding-top: 1em!important;
}
.pt-2 {
  padding-top: 2em!important;
}
.pt-3 {
  padding-top: 3em!important;
}

.pb-0 {
  padding-bottom: 0!important;
}
.pb-1 {
  padding-bottom: 1em!important;
}
.pb-2 {
  padding-bottom: 2em!important;
}
.pb-3 {
  padding-bottom: 3em!important;
}
/************* FONTS ***********/
.f-extra {
  font-size: 60pt !important;
}
.f-hero {
  font-size: 38pt !important;
}
.f-xxxl {
  font-size: 32pt !important;
}
.f-xxl {
  font-size: 26pt !important;
}
.f-xl {
  font-size: 22pt !important;
}
.f-l {
  font-size: 18pt !important;
}
.f-m {
  font-size: 14pt !important;
}
.f-r {
  font-size: 12pt !important;
}
.f-s {
  font-size: 10pt !important;
}
.f-xs {
  font-size: 8pt !important;
}
.f-xxs {
  font-size: 6pt !important;
}
.f-xxxs {
  font-size: 4pt !important;
}

.f-bold {
  font-weight: 600!important;
}

.f-light {
  color: #BDBDBD;
}

.text-center {
  text-align: center;
}
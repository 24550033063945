@font-face {
  font-family: "Source-SemiBoldItalic";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf")
    format("truetype");

  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Source-Bold";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf")
    format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source-Light";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source-SemiBold";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Source-Reg";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf")
    format("truetype");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.captcha{

  margin: 5px;
  width:250px;

}
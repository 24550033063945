.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: #d1d1d1;
  transition: color 0.2s;
  border: none;
  outline: none;
  /* align-items: center;
    justify-content: center;
    text-align: center; */
}
.bm-item:nth-child(2) {
  margin-bottom: 4px;
}

.bm-item:nth-child(3) {
  margin-bottom: 20px;
}

.bm-burger-button {
  /* position: relative;
  width: 25px;
  height: 25px;
  left: 15px;
  top: 36px; */
  position: relative;
  width: 22px;
  height: 22px;
  left: 26px;
  top: 38px;
}

.bm-burger-bars {
  background: #373a47;
}

.bm-cross-button {
  height: 24px;
  width: 24px;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.side-barround-user-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: white;
  box-shadow: 0px 12px 24px #9191914f;
  opacity: 1;
}
.user-icon {
  position: relative;
  /* top:s: 11px; */
}
.logout-menu {
  margin-top: 33px;
}

.logout-menu-item {
  flex-direction: row;
  display: flex;
  padding: 10px;
  cursor: pointer;
  margin-top: 12px;
}
.logout-menu-Item {
  flex-direction: row;
  display: flex;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  color: white;
  /* margin-top: 12px; */
}

.menu-item-active {
  background-color: #f76c6c12;
}
.logout-menu-item:hover {
  background-color: #f76c6c12;
  padding: 10px;
}
.logout-menu-Item:hover {
  background-color: #f76c6c12;
  padding: 10px;
}
.sidebar-logout-icon {
  position: relative;
  top: 2px;
  right: 4px;
}
.side-bar-username {
  margin: 0;
  color: white;
  font-size: 1em;
  font-family: "Source-SemiBold";
}
.side-bar-userEmail {
  margin: 0;
  color: white;
  font-size: 0.8em;
  font-family: "Source-Light";
}

.headerLogo {
  width: 170px;
  cursor: pointer;
  position: relative;
  top: -1px;
  /* left: 40px; */
  padding: 10px;
}
.navbar-toggle {
  background-color: black;
}
.navbar-collapse {
  background-color: black;
}
.container-fluid > .navbar-header {
  padding: 13px;
  overflow: hidden;
}
.navbar-nav > li > a {
  font-size: 1.2em;
}
.nav > li > a {
  cursor: pointer;
}

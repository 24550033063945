.subscribe-btn {
  margin-top: 12px;
}
.subscribe-input {
  padding-left: 12px !important;
  font-size: 18px !important;
}
.stripe-container {
  background-color: rgb(242, 246, 250);
  /* height: 87.6vh; */
  /* display: flex; */
  /* align-items: center; */
}
.stripe-main-container {
  margin: 0 auto;
}

.stripe-main {
  justify-content: space-between;
  display: flex;
  flex: 1px 1;
  /* margin: 0px 110px 0px 140px; */
  /* margin: 1px 140px; */
  /* margin: 10%; */
  flex-direction: row;
  /* padding: 5% 0; */
  width: 100%;
}
.stripe-left-side,
.stripe-right-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  /* z-index: 9; */
}
.stripe-right-side {
  margin-left: auto;
  padding-left: 42px;
  padding-right: 42px;
  width: 50%;
  max-width: 580px;
  box-shadow: rgb(204, 204, 204) 5px 5px 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background-color: white;
  /* margin-top: 70px; */
}
.stripe-plan-title {
  font-size: 2.8em;
  color: white;
  font-family: Source-SemiBold;
  margin: 0px;
  text-align: center;
}
.stripe-left-side {
  margin-right: auto;
  /* max-width: 20%; */
  /* background-color: green; */
  width: 40%;
}
.subs-left-arrow-align {
  flex-direction: row;
  display: flex;
  /* margin-bottom: 40px; */
}
.subs-left-arrow {
  cursor: pointer;
  width: 20px;
  position: relative;
  top: 8px;
}
.subscription-top-text {
  margin: 0;
  margin-left: 10px;
  margin-top: 30px;
  font-size: 1em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
}
.subs-left-title {
  font-size: 1.5em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
  margin-bottom: 10px;
}
.subs-left-subtext {
  font-size: 1.2em;
  color: rgb(0, 0, 0);
  margin: 0px;
}
.subs-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: rgb(204, 204, 204) 5px 5px 10px;
  /* padding: 20px; */
  width: 100%;
}
.subs-card-align {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 20px;
}
.plan-details-align {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 13px;
}
.subs-card-left-align {
  padding-right: 40px;
  padding-left: 5px;
}
.subs-right-text {
  font-size: 1.2em;
  font-family: Source-SemiBold;
  color: rgb(5, 25, 47);
  /* margin: 20px 0px 25px; */
  margin: 0px;
  margin-bottom: 15px;
}

.subs-right-subtext {
  margin: 0px 0px 0px 10px;
}

.stripe-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}
.stripe-left-cards-wrap {
  width: 100%;
}
.stripe-first-card {
  box-shadow: rgb(136, 136, 136) 0px 0px 1px;
  background-color: rgb(63, 77, 133);
  /* max-width: 273px; */
  /* padding: 30px; */
  padding: 1px 38px;
  border-radius: 8px;
  height: 195px;
  display: flex;
  /* margin-top: 40px; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stripe-plan-subtext {
  margin: 0px;
  text-align: center;
  color: rgba(255, 255, 255, 0.822);
  font-family: Source-Reg;
  font-size: 1.8em;
}
@media (max-width: 575.98px) {
  .stripe-main-container {
    max-width: 100%;
  }
  .stripe-left-side {
    margin: 0 auto;
    width: 90%;
    margin-bottom: 10px;
  }
  .stripe-right-side {
    /* margin-left: 25px; */
    margin: 0 auto;
    width: 90%;
    margin-bottom: 20px;
    padding-left: 44px;
    padding-right: 44px;
  }
  .stripe-main {
    flex-direction: column;
    /* margin-left: 6%; */
  }
  .subs-left-arrow {
    top: 14px;
  }
  /* .stripe-container{
      height:100vh;
    } */
  .subs-btn-align {
    position: relative;
    bottom: -37px;
  }
  .cards-wrap {
    width: 100%;
    /* height: 130px; */
    margin-bottom: 25px;
  }

  #cw2{
    order: 1;
  }
  #cw1{
    order: 2;
  }
  #cw0{
    order: 3;
  }

  
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .stripe-main-container {
    max-width: 540px;
  }

  /* .stripe-left-side{
      margin-left:0  auto;
      width:90%;
  
    } */
  .stripe-right-side {
    padding: 10px;
    /* margin-left: 0 auto;
      width:90%; */
  }
  .stripe-main {
    /* flex-direction: column; */
    /* margin-left: 6%; */
  }
  .subs-left-arrow {
    top: 14px;
  }
  /* .stripe-container{
      height:100vh;
    } */
  .subs-btn-align {
    position: relative;
    bottom: -37px;
  }
  .cards-wrap {
    width: 100%;
    /* height: 130px; */
    margin-bottom: 25px;
  }
  .stripe-plan-title {
    font-size: 1.8em;
  }
  .stripe-plan-subtext {
    font-size: 1.7em;
  }

#cw2{
  order: 1;
}
#cw1{
  order: 2;
}
#cw0{
  order: 3;
}
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .stripe-main-container {
    max-width: 720px;
  }
  /* .stripe-left-side{
      width:25%;
    }
    .stripe-right-side{
      width:75%;
    } */

  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .stripe-main-container {
    max-width: 960px;
  }
  /* .stripe-left-side{
      width:25%;
    }
    .stripe-right-side{
      width:75%;
    } */

  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1200px) and (max-width: 1367.98px) {
  .stripe-main-container {
    max-width: 1140px;
  }
  /* .stripe-left-side{
      width:25%;
    }
    .stripe-right-side{
      width:75%;
    } */

  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1368px) and (max-width: 1919.98px) {
  .stripe-main-container {
    max-width: 1200px;
  }
  /* .stripe-left-side{
      width:20%;
    } */
  /* .stripe-container {
    height: 87.6vh;
  } */
  .subs-left-arrow {
    top: 14px;
  }
}
@media (min-width: 1920px) {
  .stripe-main-container {
    max-width: 1700px;
  }
  /* .stripe-left-side{
      width:20%;
    } */
  .stripe-container {
    height: 87vh;
  }
  .subs-left-arrow {
    top: 14px;
  }
}
